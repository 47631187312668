body {
  height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

.app {
  display: flex;
  height: 100%;
}

main {
  padding: 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#logout {
  margin-left: 1.3rem;
  text-decoration: none;
  color: #adadad;
}

.goal {
  margin-bottom: 10px;
}

.RSPBprogressBar .RSPBprogressBarText {
  color: #000000;
}